import React, { useEffect } from "react";
import "./App.scss";

import Footer from "../components/Footer";
import Header from "../components/Header";

import Contact from "../components/home/Contact";
import Hero from "../components/home/Hero";
import News from "../components/home/News";
import Partners from "../components/home/Partners";
import People from "../components/home/People";
import Packages from "../components/home/Packages";

function HomePage() {
	return (
		<div className="App bg-background">
			<div className="hero-gradient pt-3">
				<Header />
				<Hero />
			</div>
			{/* <Partners /> */}
			{/* <Packages /> */}
			{/* <People /> */}
			{/* <Contact /> */}
			{/* <News /> */}
			<div className="footer-gradient">
				<Footer />
			</div>
		</div>
	);
}

export default HomePage;
