import React from "react";
import { Container, Navbar, Nav, Button, NavDropdown } from "react-bootstrap";
import logo from "../assets/Logo.svg";
import { Link } from "react-router-dom";

function Header() {
	return (
		<Navbar collapseOnSelect variant="dark" expand="lg">
			<Container>
				<Navbar.Brand href="/">
					<img src={logo} alt="logo" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="ms-auto gap-1">
						<Nav.Link href="/" className="mt-1 mt-md-0">
							Home
						</Nav.Link>
						{/* <Nav.Link href="/portfolio" className="me-0 me-md-1 mb-1 mb-md-0">
							Portfolio
						</Nav.Link> */}
						{/* <NavDropdown
							title="Dropdown"
							id="collapsible-nav-dropdown"
							data-bs-theme="dark"
							className="me-0 me-md-1 mb-1 mb-md-0"
						>
							<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.2">
								Another action
							</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item href="#action/3.4">
								Separated link
							</NavDropdown.Item>
						</NavDropdown> */}

						<Link to="mailto:sebastian@mandal.group">
							<Button variant="secondary">Contact</Button>
						</Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Header;
